import {
  buildCollection,
  buildProperty,
  buildEntityCallbacks,
  EntityOnSaveProps,
  EntityOnDeleteProps,
} from "@firecms/core";
import { InformationEntityView } from "./entity_views/InformationEntityView";
type InfoEntry = {
  name: string;

  content: any[];
  status: string;
  created: Date;
  updated: Date;
};

const infoCallbacks = buildEntityCallbacks({
  onSaveSuccess: (props: EntityOnSaveProps<InfoEntry>) => {
    fetch(`https://closures.pcta.org/api/revalidate?type=info&id=${props.entityId}`);
  },
  onDelete: (props: EntityOnDeleteProps<InfoEntry>) => {
    fetch(`https://closures.pcta.org/api/revalidate?type=info&id=${props.entityId}`);
  },
});
export const infoCollection = buildCollection<InfoEntry>({
  id: "information",
  name: "Information",
  path: "information",
  callbacks: infoCallbacks,
  editable: false,
  textSearchEnabled: true,
  exportable: true,
  importable: true,
  initialSort: ["name", "asc"],
  permissions: ({ authController, user }) => ({
    read: true,
    edit: true,
    create: true,
    delete: true
  }),
  entityViews: [
    {
      key: "information_entity_view",
      name: "Preview Information",
      Builder: InformationEntityView,
    },
  ],
  properties: {
    name: buildProperty({
      name: "Name",
      validation: { required: true },
      dataType: "string",
      editable: false,
    }),
    status: buildProperty(({ values }) => ({
      name: "Status",
      validation: { required: true },
      dataType: "string",
      columnWidth: 140,
      editable: false,
      enumValues: {
        published: {
          id: "published",
          label: "Published",
          disabled: !values.header_image,
        },
        draft: "Draft",
      },
      defaultValue: "draft",
    })),

    content: buildProperty({
      name: "Content",
      description: "Body Content of the Closure",
      validation: { required: true },
      dataType: "array",
      columnWidth: 400,
      editable: false,
      oneOf: {
        properties: {
          text: buildProperty({
            dataType: "string",
            name: "Text",
            multiline: true,
            editable: false,
          }),
          heading_1: buildProperty({
            dataType: "string",
            name: "Heading 1",
            editable: false,
          }),
          heading_2: buildProperty({
            dataType: "string",
            name: "Heading 2",
            editable: false,
          }),
          /* link: buildProperty({
                        dataType: "string",
                        name: "Link",
                        url: true
                    }), */
          link: {
            name: "Link",
            description: "Hyperlink (Online Only)",
            dataType: "map",
            editable: false,
            properties: {
              label: {
                name: "Link Text",
                dataType: "string",
                editable: false,
              },
              url: {
                name: "URL",
                dataType: "string",
                url: true,
                editable: false,
              },
            },
            expanded: true,
          },
          email: buildProperty({
            name: "Email",
            description: "Email",
            dataType: "map",
            editable: false,
            properties: {
              label: {
                name: "Label",
                dataType: "string",
                editable: false,
              },
              email: {
                name: "Email Address",
                dataType: "string",
                email: true,
                editable: false,
              },
            },
            expanded: true,
          }),
          phone: buildProperty({
            name: "Phone Number - 1.1 Testing DO NOT USE",
            description: "Phone Number - 1.1 Testing DO NOT USE",
            dataType: "map",
            editable: false,
            properties: {
              label: {
                name: "Label",
                dataType: "string",
                editable: false,
                required: true,
              },
              phone: {
                name: "Phone Number",
                dataType: "string",
                editable: false,
              },
            },
            expanded: true,
          }),
          image: buildProperty({
            name: "Image",
            dataType: "string",
            validation: { required: true },
            editable: false,
            storage: {
              mediaType: "image",
              storagePath: "images",
              acceptedFiles: ["image/*"],
              metadata: {
                cacheControl: "max-age=1000000",
              },
              imageCompression: {
                maxWidth: 2560,
                quality: 80,
              },
            },
          }),
          image2: buildProperty({
            name: "Image with Metadata - 1.1 Testing DO NOT USE",
            description: "Image with metadata captions and alt text.  1.1 Testing DO NOT USE",
            dataType: "map",
            editable: false,
            properties: {
              image: {
                dataType: "string",
                validation: { required: true },
                storage: {
                  mediaType: "image",
                  storagePath: "images",
                  acceptedFiles: ["image/*"],
                  metadata: {
                    cacheControl: "max-age=1000000",
                  },
                  imageCompression: {
                    maxWidth: 2560,
                    quality: 80,
                  },
                },
              },
              caption: {
                name: "Caption",
                dataType: "string",
                multiline: true,
              },
              alt_text: {
                name: "Alt Text",
                dataType: "string",
              },
            }
            
            
          }),
          pdf: {
            name: "PDF",
            description: "PDF",
            dataType: "map",
            editable: false,
            properties: {
              label: {
                name: "PDF Label Text",
                dataType: "string",
                editable: false,
              },
              file: {
                name: "PDF",
                dataType: "string",
                editable: false,
                storage: {
                  mediaType: "pdf",
                  storagePath: "files",
                  acceptedFiles: ["application/pdf"],
                  metadata: {
                    cacheControl: "max-age=1000000",
                  },
                },
              },
            },
            expanded: true,
          },
        },
      },
    }),
    created: buildProperty({
      name: "Created",
      dataType: "date",
      autoValue: "on_create",
      editable: false,
    }),
    updated: buildProperty({
      name: "Updated",
      dataType: "date",
      autoValue: "on_update",
      editable: false,
    }),
  },
});
